
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiChevronDown, mdiChevronUp, mdiFilter, mdiPlus, mdiCamera } from '@mdi/js'
import { cloneDeep, isEqual } from 'lodash-es'
import { useNotify } from '@/store'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenuExtended from '@/components/common/CommonExportMenuExtended.vue'
import DotIndicator from '@/components/common/CommonDotIndicator.vue'

import { useFilter } from '@/composables/useFilter'

import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { dateDotNotationToDash } from '@/utils/convertDate'
import { convertPositionStatus } from '@/utils/convertPositionStatus'
import { convertSourcingType } from '@/utils/convertSourcingType'

import {
  useDeletePosition,
  useExportPositions,
  useExportPositionsExtended,
  useGetPositions,
  useGetPositionsBasic,
} from '@/api/position'
import { useGetProfilesBasic } from '@/api/profile'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useGetProfUnitsBasic } from '@/api/profUnit'

import { Rights } from '@/api/types/right'
import { PositionOutput } from '@/api/types/position'
import { PositionTransactionOutput } from '@/api/types/positionTransaction'
import { ACTIVE_TENDERING, POSITIONSTATUS, POSITION_STATUS, SOURCING_TYPE } from './types'
import { FILTER_FIELD_KEY } from '@/views/planning/views/workforcePlanning/types'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { setSort } from '@/utils/manipulateTableSort'
import { useGetAssignments } from '@/api/assignment'
import { AssignmentOutput } from '@/api/types/assignment'
import { PROFILE_TYPE } from '@/api/types/profile'
import { CONTRACTUAL_RELATIONSHIP } from '@/views/types'
import { isOfType } from '@/utils/types/isOfType'

export default defineComponent({
  name: 'WorkforcePlanning',
  components: {
    CommonTableView,
    CommonExportMenuExtended,
    DotIndicator,
    AddEditPositionDialog: () =>
      import('@/views/planning/views/workforcePlanning/components/AddEditPositionDialog.vue'),
    AddEditTransactionDialog: () =>
      import('@/views/planning/views/workforcePlanning/components/AddEditTransactionDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    WorkforcePlanningFilterBar: () =>
      import('@/views/planning/views/workforcePlanning/components/WorkforcePlanningFilterBar.vue'),
    PositionScenarioDialog: () =>
      import('@/views/planning/views/workforcePlanning/components/positionScenario/PositionScenarioDialog.vue'),
    PositionCommitsDialog: () =>
      import('@/views/planning/views/workforcePlanning/components/PositionCommitsDialog.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const activePosition = ref<PositionOutput | PositionTransactionOutput | null>(null)
    const activeTransaction = ref<null | PositionOutput['sourceTransaction']>(null)

    const isAddEditPositionDialogOpen = ref(false)
    const isAddEditTransactionDialogOpen = ref(false)
    const isDeleteDialogOpen = ref(false)
    const isPositionCommitsDialogOpen = ref(false)
    const isFilterDropdownOpen = ref(false)
    const isPositionScenarioDialogOpen = ref(false)

    const {
      exec: getPositions,
      data: positions,
      isLoading: isLoadingPositions,
      paginationResponse,
    } = useGetPositions()
    const { exec: getAssignments, data: assignments, isLoading: isLoadingAssignments } = useGetAssignments()

    const isLoading = computed(() => isLoadingPositions.value || isLoadingAssignments.value)

    const TABLE_HEADERS = [
      {
        text: root.$t('planning.workforcePlanning.table.col.title.id'),
        value: 'id',
        sortable: true,
        width: 150,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.assigned'),
        value: 'currentlyAssigned',
        sortable: false,
        width: 100,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.plannedPerson'),
        value: 'plannedPerson',
        sortable: false,
        width: 150,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.contractualRelationships'),
        value: 'contractualRelationships',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.sourcingType'),
        value: 'sourcingType',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
        width: 200,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.secondLevelProfessionalUnit'),
        value: 'secondLevelProfessionalUnit',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.professionalUnit'),
        value: 'professionalUnit',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.profile'),
        value: 'profile',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.scope'),
        value: 'scope',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.start'),
        value: 'start',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.end'),
        value: 'end',
        sortable: false,
      },
      hasSufficientRights(Rights.FINANCIAL_READ) && {
        text: root.$t('planning.workforcePlanning.table.col.title.hourlyRate'),
        value: 'hourlyRate',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.positionStatus'),
        value: 'positionStatus',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.budgetSourceOrgUnit'),
        value: 'budgetSourceOrgUnit.name',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.unlimited'),
        value: 'limited',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.approved'),
        value: 'approvals',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const { deletePosition: deletePositionXhr } = useDeletePosition()

    async function deletePosition(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deletePositionXhr(activePosition.value!.id)

        addNotification({
          text: root.$t('planning.workforcePlanning.delete.message') as string,
          type: 'success',
        })

        isDeleteDialogOpen.value = false

        debouncedCb.value()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function onPositionEdit(position: PositionOutput): void {
      isAddEditPositionDialogOpen.value = true

      activePosition.value = cloneDeep(position)
    }

    function onPositionDelete(position: PositionOutput): void {
      activePosition.value = cloneDeep(position)
      if (!activePosition.value.sourceTransaction) {
        isDeleteDialogOpen.value = true
      } else {
        activeTransaction.value = activePosition.value.sourceTransaction
        isAddEditTransactionDialogOpen.value = true
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onDblClickRow([_, { item: position }]: [void, { item: PositionOutput }]): void {
      root.$router.push({
        name: 'position-details',
        params: { positionId: String(position.id) },
      })
    }

    function onClickInfo(position: PositionOutput): void {
      activePosition.value = { ...position }

      isPositionCommitsDialogOpen.value = true
    }

    function onCloseAddEditCommitsDialog(): void {
      isAddEditPositionDialogOpen.value = false

      isAddEditTransactionDialogOpen.value = false

      activePosition.value = null

      activeTransaction.value = null

      isPositionCommitsDialogOpen.value = false
    }

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (
          !profUnits.value?.length ||
          !orgUnits.value?.length ||
          !profiles.value?.length ||
          !positionsBasic.value?.length
        ) {
          getProfUnitsBasic()
          getOrgUnitsBasic()
          getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })
          getPositionsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnits } = useGetProfUnitsBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnits } = useGetOrgUnitsBasic()

    const { exec: getProfilesBasic, data: profiles, isLoading: isLoadingProfiles } = useGetProfilesBasic()

    const {
      exec: getPositionsBasic,
      data: positionsBasic,
      isLoading: isLoadingPositionsBasic,
    } = useGetPositionsBasic()

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.PersonName,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.person'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Ids,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.ids'),
        items: computed(() => positionsBasic.value ?? []),
        isLoading: computed(() => isLoadingPositionsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.professionalUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.professionalUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfileIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.profile'),
        items: computed(() => profiles.value ?? []),
        isLoading: computed(() => isLoadingProfiles.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.BudgetSourceOrgUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.budgetSourceOrgUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.StartTo,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.startTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.EndFrom,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.endFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ContractualRelationships,
        value: null,
        label: root.$t('contractData.contracts.filters.contractualRelationships'),
        items: computed(() => CONTRACTUAL_RELATIONSHIP),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.SourcingTypes,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.sourcingTypes'),
        items: computed(() => SOURCING_TYPE ?? []),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.ScopeFrom,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.scopeFrom'),
        items: [],
        dataTyp: FilterDataTypes.Float,
      },
      {
        key: FILTER_FIELD_KEY.ScopeTo,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.scopeTo'),
        items: [],
        dataTyp: FilterDataTypes.Float,
      },
      {
        key: FILTER_FIELD_KEY.PositionStatuses,
        value: POSITIONSTATUS.ACTIVE,
        label: root.$t('planning.workforcePlanning.filters.positionStatuses'),
        items: computed(() => POSITION_STATUS ?? []),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.ActiveTendering,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.activeTendering'),
        items: computed(() => ACTIVE_TENDERING ?? []),
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.NotAssignedByDate,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.notAssignedByDate'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.Limited,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.limited'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.NotApproved,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.notApproved'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )
    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'professionalUnit', desc: false })

    const totalPositions = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getPositions({
        params: { sitze: 9999, ...paginationParams.value, ...filterFieldsObject },
      })

      await getAssignments({
        params: {
          size: 9999,
          positionIds: positions.value.map((position) => position.id).join(','),
        },
      })
    }

    const { exportPositions, isLoading: isLoadingExportPositions } = useExportPositions()
    const { exportPositions: exportPositionsExtended, isLoading: isLoadingExportPositionsExtended } =
      useExportPositionsExtended()

    async function onExportPositions(exportDetails: {
      startMonth: string
      endMonth: string
      isExtendedExport: boolean
    }) {
      try {
        if (exportDetails.isExtendedExport) {
          await exportPositionsExtended({
            ...filterFieldsObject.value,
            startMonth: dateDotNotationToDash(exportDetails.startMonth),
            endMonth: dateDotNotationToDash(exportDetails.endMonth),
          })
        } else {
          await exportPositions({
            ...filterFieldsObject.value,
            startMonth: dateDotNotationToDash(exportDetails.startMonth),
            endMonth: dateDotNotationToDash(exportDetails.endMonth),
          })
        }

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const isLoadingExport = computed(() => isLoadingExportPositions.value || isLoadingExportPositionsExtended.value)

    function onClosePositionScenarioDialogDialog(): void {
      isPositionScenarioDialogOpen.value = false
    }

    function hasMultiplePersonsPlanned(personName: string): boolean {
      if (!personName) return false

      return personName.includes(',')
    }

    function convertStringToArray(personName: string): string[] {
      return personName.split(', ')
    }

    function getAssignmentsDetails(position: PositionOutput): AssignmentOutput[] {
      return assignments.value.filter((assignment) =>
        position.assignments.some((positionAssignment) => positionAssignment.id === assignment.id)
      )
    }

    function onCloseDeleteDialog(): void {
      activePosition.value = null
      isDeleteDialogOpen.value = false
    }

    function hasTargetTransaction(position: PositionOutput): boolean {
      if (isOfType<PositionOutput>(position, 'sourceTransaction')) {
        return Boolean(position?.targetTransactions?.length)
      } else return false
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiFilter,
        mdiChevronDown,
        mdiChevronUp,
        mdiCamera,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        FILTER_FIELDS,
      },
      state: {
        positions,
        isLoadingExport,

        assignments,

        isLoading,

        isDeleteDialogOpen,

        isAddEditPositionDialogOpen,
        activePosition,

        isAddEditTransactionDialogOpen,
        activeTransaction,

        totalPositions,
        vuetifyTableOptions,

        isFilterDropdownOpen,
        isPositionScenarioDialogOpen,

        isPositionCommitsDialogOpen,
      },
      functions: {
        deletePosition,

        debouncedCb,

        hasSufficientRights,

        convertPositionStatus,
        convertSourcingType,

        hasMultiplePersonsPlanned,
        convertStringToArray,

        getAssignmentsDetails,
        hasTargetTransaction,
      },
      listeners: {
        onCloseAddEditCommitsDialog,
        onPositionEdit,
        onPositionDelete,
        onCloseDeleteDialog,

        onDblClickRow,
        onToggleFilterDropdown,

        onClosePositionScenarioDialogDialog,

        onExportPositions,

        onClickInfo,
      },
    })
  },
})
