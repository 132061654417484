var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.positions,
      "loading": _vm.state.isLoading,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalPositions,
      "with-edit": _vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_UPDATE)
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": function dblclickRow() {
        var arguments$1 = arguments;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments$1[_key];
        }

        return _vm.listeners.onDblClickRow(args);
      },
      "click:edit": function clickEdit($event) {
        return _vm.listeners.onPositionEdit($event);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onPositionDelete($event);
      },
      "click:info": function clickInfo($event) {
        return _vm.listeners.onClickInfo($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pb-3 ma-0",
          attrs: {
            "align": "center"
          }
        }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "mr-5 rounded-lg white",
          attrs: {
            "text": "",
            "elevation": "0",
            "color": "primary"
          },
          on: {
            "click": _vm.listeners.onToggleFilterDropdown
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1) : _vm._e(), _c('CommonExportMenuExtended', {
          attrs: {
            "loading": _vm.state.isLoadingExport
          },
          on: {
            "export": function _export($event) {
              return _vm.listeners.onExportPositions($event);
            }
          }
        }), _vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_SCENARIO_READ) ? _c('v-btn', {
          staticClass: "ml-auto rounded-lg button--background",
          attrs: {
            "outlined": "",
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isPositionScenarioDialogOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiCamera) + " ")]), _vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.actions.openScenarios')) + " ")], 1) : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_CREATE) ? _c('v-btn', {
          staticClass: "ml-4 rounded-lg button--background",
          attrs: {
            "outlined": "",
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditPositionDialogOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.actions.addPosition')) + " ")], 1) : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_TRANSACTION_CREATE) ? _c('v-btn', {
          staticClass: "ml-4 rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditTransactionDialogOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.actions.addTransaction')) + " ")], 1) : _vm._e()], 1), _c('v-expand-transition', {
          attrs: {
            "mode": "in-out"
          }
        }, [_vm.state.isFilterDropdownOpen ? _c('WorkforcePlanningFilterBar', {
          staticClass: "d-flex align-center",
          attrs: {
            "fields": _vm.constants.FILTER_FIELDS
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "header.currentlyAssigned",
      fn: function fn(_ref) {
        var header = _ref.header;
        return [_c('div', {
          staticClass: "header-wrapper"
        }, [_c('span', {
          staticClass: "text-subtitle-2 font-weight-bold black--text"
        }, [_vm._v(_vm._s(_vm.$t('planning.workforcePlanning.positionDetails.cast')))]), _c('span', [_vm._v(_vm._s(header.text))])])];
      }
    }, {
      key: "header.sourcingType",
      fn: function fn(_ref2) {
        var header = _ref2.header;
        return [_c('div', {
          staticClass: "header-wrapper"
        }, [_c('span', {
          staticClass: "text-subtitle-2 font-weight-bold black--text"
        }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionDetails.position.title')) + " ")]), _c('span', [_vm._v(_vm._s(header.text))])])];
      }
    }, {
      key: "plannedPerson",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm.functions.hasMultiplePersonsPlanned(item === null || item === void 0 ? void 0 : item.plannedPerson) ? _c('div', {
          staticClass: "d-flex flex-column"
        }, _vm._l(_vm.functions.convertStringToArray(item.plannedPerson), function (name, index) {
          return _c('span', {
            key: name + index
          }, [_vm._v(" " + _vm._s(name) + " ")]);
        }), 0) : _c('span', [_vm._v(_vm._s(item.plannedPerson))])];
      }
    }, {
      key: "currentlyAssigned",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('DotIndicator', {
          attrs: {
            "color": "".concat(item.currentlyAssigned ? 'green' : 'red')
          }
        })];
      }
    }, {
      key: "positionStatus",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.functions.convertPositionStatus(item.positionStatus)) + " ")])];
      }
    }, {
      key: "contractualRelationships",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return _vm._l(_vm.functions.getAssignmentsDetails(item), function (assignment) {
          return _c('div', {
            key: assignment.id
          }, _vm._l(assignment.contractualRelationships, function (contractualRelationship) {
            return _c('span', {
              key: contractualRelationship
            }, [_vm._v(" " + _vm._s(contractualRelationship) + " ")]);
          }), 0);
        });
      }
    }, {
      key: "sourcingType",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.functions.convertSourcingType(item.sourcingType)) + " ")])];
      }
    }, {
      key: "approvals",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('DotIndicator', {
          attrs: {
            "color": "".concat(item.approvals.length ? 'green' : 'red')
          }
        })];
      }
    }, {
      key: "item.actions.delete",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_DELETE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_TRANSACTION_DELETE) || _vm.functions.hasTargetTransaction(item) ? _c('div') : _vm._e()];
      }
    }], null, true)
  }, [_vm.state.isPositionCommitsDialogOpen ? _c('PositionCommitsDialog', {
    attrs: {
      "position": _vm.state.activePosition
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditCommitsDialog();
      }
    },
    model: {
      value: _vm.state.isPositionCommitsDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isPositionCommitsDialogOpen", $$v);
      },
      expression: "state.isPositionCommitsDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditPositionDialogOpen ? _c('AddEditPositionDialog', {
    attrs: {
      "position-to-edit": _vm.state.activePosition
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditCommitsDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditPositionDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditPositionDialogOpen", $$v);
      },
      expression: "state.isAddEditPositionDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditTransactionDialogOpen ? _c('AddEditTransactionDialog', {
    attrs: {
      "transaction-to-edit": _vm.state.activeTransaction
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditCommitsDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditTransactionDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditTransactionDialogOpen", $$v);
      },
      expression: "state.isAddEditTransactionDialogOpen"
    }
  }) : _vm._e(), _vm.state.isPositionScenarioDialogOpen ? _c('PositionScenarioDialog', {
    on: {
      "close": function close($event) {
        return _vm.listeners.onClosePositionScenarioDialogDialog();
      }
    },
    model: {
      value: _vm.state.isPositionScenarioDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isPositionScenarioDialogOpen", $$v);
      },
      expression: "state.isPositionScenarioDialogOpen"
    }
  }) : _vm._e(), _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseDeleteDialog();
      },
      "delete": function _delete($event) {
        return _vm.functions.deletePosition();
      }
    },
    model: {
      value: _vm.state.isDeleteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteDialogOpen", $$v);
      },
      expression: "state.isDeleteDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.deletePosition.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }